<template>
    <div>
        <van-sticky>
            <van-dropdown-menu>
                <van-dropdown-item v-model="option1Value" :options="option1" @change="handleChange"></van-dropdown-item>
                <van-dropdown-item v-model="option2Value" :options="option2" @change="handleChange"></van-dropdown-item>
            </van-dropdown-menu>
        </van-sticky>
        <van-list v-model="listLoading" :finished="listFinished" @load="getList" finished-text="没有更多了">
            <div class="sumjvancellgroup" v-for="(item, index) in listData" :key="index" @click="$router.push({ name: 'WapLYT001Operate', params: { materielId: item.id } })">
                <van-swipe-cell :before-close="deleteMateriel" :name="index">
                    <van-cell-group :title="item.materielCode">
                        <van-cell title="来料时间" :value="item.materielTime"></van-cell>
                        <van-cell title="地址" :value="item.address"></van-cell>
                        <van-cell title="质量" :value="item.quality"></van-cell>
                    </van-cell-group>
                    <template #right>
                        <van-button type="danger" text="删除" class="delete-button" />
                    </template>
                </van-swipe-cell>
            </div>
        </van-list>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);
export default {
    data() {
        return {
            pageSize: 10,
            currentPage: 1,
            option1Value: 7,
            option2Value: "desc",
            option1: [
                { text: "近7天", value: 7 },
                { text: "近30天", value: 30 },
                { text: "全部", value: 0 },
            ],
            option2: [
                { text: "时间降序", value: "desc" },
                { text: "时间升序", value: "asc" },
            ],
            listLoading: false,
            listFinished: false,
            listData: [],
        };
    },
    methods: {
        getList() {
            let that = this;

            that.axios
                .post("Materiel/GetList", {
                    days: that.option1Value,
                    order: that.option2Value,
                    pageSize: that.pageSize,
                    currentPage: that.currentPage,
                })
                .then(function (response) {

                    response.data.data.data.forEach((element) => {
                        that.listData.push(element);
                    });
                    that.listLoading = false;
                    if (that.listData.length >= response.data.data.total) {
                        that.listFinished = true;
                    } else {
                        that.currentPage++;
                    }
                });
        },
        handleChange() {
            let that = this;
            that.listLoading = true;
            that.listFinished = false;
            that.listData = [];
            that.currentPage = 1;
            that.getList();
        },
        deleteMateriel({ position, name, instance }) {
            let that = this;
            if (position == "right") {
                that.$dialog.confirm({ message: "确定要删除吗？" }).then(function () {
                    that.axios
                        .post("Materiel/DelModel", { onlyInt: that.listData[name].id })
                        .then(function () {
                            that.listData.splice(name, 1);
                            instance.close();
                        });
                });
            }
        },
    },
    mounted() { },
};
</script>

<style scoped>
.sumjvancellgroup {
    margin: 10px;
    padding: 2px;
    border: 1px solid #ebedf0;
    border-radius: 10px;
}

.delete-button {
    height: 100%;
}
</style>